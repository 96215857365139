<template>
    <div>
        <block v-if="loading" />
        <v-card flat class="pa-4 bar">
            <div class="d-flex justify-end align-center">
                <v-btn small class="btn-add mr-1" @click="printconfig">
                    <v-icon>
                        mdi-printer
                    </v-icon>
                </v-btn>
                <v-btn small class="btnsave mr-1" @click="getReportSales">
                    <v-icon>
                        mdi-magnify
                    </v-icon>

                </v-btn>
            </div>
        </v-card>
        <v-row class="mt-4">
            <v-col cols="12" md="6">
                <v-select v-model="search.month" :items="monthList" item-text="MES" item-value="monthIndex" label="Mes"
                    outlined attach dense @change="getReportSales"></v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-select v-model="search.year" :items="yearLIst" item-text="AÑO" item-value="AÑO" label="Año" outlined
                    attach dense @change="getmonths"></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-radio-group v-model="reportType" row>

                    <v-radio label="Contribuyentes" value="1"></v-radio>
                    <v-radio label="Consumidores Finales" value="2"></v-radio>

                </v-radio-group>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-radio-group v-model="dataType">
                    <v-radio label="Ver reporte con datos de prueba" value="00"></v-radio>
                    <v-radio label="Ver reporte con datos de producción" value="01"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <hr/>
        <br/>
        <v-row>
            <v-col cols="12">
                <v-radio-group v-model="printType" row>
                    <v-radio label="Ver formato csv" value="1"></v-radio>
                    <v-radio label="Ver oficial hacienda" value="2"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>

        <template v-if="printType == '1'">
            <GeneralFilter v-if="reportType === '1'" :filterEndpoint="$API.accountingreports.getFilterSalesByDate"
                :search="{ ...search, reportType, dataType }" @emptyFilter="getReportSales" @filtered="filterHandler">
                <DxDataGrid v-if="reportType === '1'" :dataSource="DataTable" :columns="columnsTaxPayer" @exporting="onExporting"
                    :word-wrap-enabled="true" :column-auto-width="true" :scrolling="{ useNative: true }">
                    <DxExport :enabled="true" />
                    <DxPager :showPageSizeSelector="true" allowedPageSizes="[5, 10, 20]" />
                    <DxPaging defaultPageSize="5" />
                </DxDataGrid>
            </GeneralFilter>
            <DxDataGrid v-if="reportType === '2'" :dataSource="DataTable2" :columns="columnsFinalCustomer" @exporting="onExporting"
                :word-wrap-enabled="true" :column-auto-width="true" :scrolling="{ useNative: true }">
                <DxExport :enabled="true" />
                <DxPager :showPageSizeSelector="true" allowedPageSizes="[5, 10, 20]" />
                <DxPaging defaultPageSize="5" />
            </DxDataGrid>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12" md="2">
                    <v-btn class="btn-add" @click="onExportingToPdf()">Imprimir formato oficial</v-btn>
                </v-col>
                <v-col cols="12" md="10">
                    <v-checkbox v-model="hidePagination" label="Ocultar numeración de páginas"></v-checkbox>
                </v-col>
            </v-row>

            <template v-if="reportType === '2'">
                <br/>
            </template>

            <GeneralFilter v-if="reportType === '1'" :filterEndpoint="$API.accountingreports.getFilterSalesByDate"
                :search="{ ...search, reportType, dataType }" @emptyFilter="getReportSales" @filtered="filterHandler">
                <DxDataGrid :ref="nameDxDataGrid + reportType + printType" v-if="reportType === '1'" :dataSource="DataTable" :columns="columnsTaxPayer2" @exporting="onExporting"
                    :word-wrap-enabled="true" :column-auto-width="true" :scrolling="{ useNative: true }">
                    <DxExport :enabled="true" />
                    <DxPager :showPageSizeSelector="true" allowedPageSizes="[5, 10, 20]" />
                    <DxPaging defaultPageSize="5" />

                    <DxSummary>
                        <DxTotalItem
                        column="FECHA EMISIÓN DEL DOCUMENTO"
                        display-format="TOTALES"
                        />
                        <DxTotalItem
                        column="ExportacionesFormatted"
                        summary-type="sum"
                        :value-format="customCurrencyFormat"
                        display-format="${0}"
                        cssClass="right-column"
                        
                        />
                        <DxTotalItem
                        column="VENTAS GRAVADAS LOCALES"
                        summary-type="sum"
                        :value-format="customCurrencyFormat"
                        display-format="${0}"
                        cssClass="right-column"
                        />
                        <DxTotalItem
                        column="DÉBITO FISCAL"
                        summary-type="sum"
                        :value-format="customCurrencyFormat"
                        display-format="${0}"
                        cssClass="right-column"
                        />
                        <DxTotalItem
                        column="DÉBITO FISCAL POR VENTA A CUENTA DE TERCEROS"
                        summary-type="sum"
                        :value-format="customCurrencyFormat"
                        display-format="${0}"
                        cssClass="right-column"
                        />
                        <DxTotalItem
                        column="ImpuestoPercibidoFormatted"
                        summary-type="sum"
                        :value-format="customCurrencyFormat"
                        display-format="${0}"
                        cssClass="right-column"
                        />
                        <DxTotalItem
                        column="TOTAL VENTAS"
                        summary-type="sum"
                        :value-format="customCurrencyFormat"
                        display-format="${0}"
                        cssClass="right-column"
                        />
                    </DxSummary>
                </DxDataGrid>
            </GeneralFilter>
            <DxDataGrid :ref="nameDxDataGrid + reportType + printType" v-if="reportType === '2'" :dataSource="DataTable2" :columns="columnsFinalCustomer2" @exporting="onExporting"
                :word-wrap-enabled="true" :scrolling="{ useNative: true }">
                <DxExport :enabled="true" />
                <DxPager :showPageSizeSelector="true" allowedPageSizes="[5, 10, 20]" />
                <DxPaging defaultPageSize="5" />
            
                <DxSummary>
                    <DxTotalItem
                    column="InvoiceDate"
                    display-format="TOTALES"
                    />
                    <DxTotalItem
                    column="VentasExentas"
                    summary-type="sum"
                    :value-format="customCurrencyFormat"
                    display-format="${0}"
                    cssClass="right-column"                        
                    />
                    <DxTotalItem
                    column="VentasGravadasLocales"
                    summary-type="sum"
                    :value-format="customCurrencyFormat"
                    display-format="${0}"
                    cssClass="right-column"
                    />
                    <DxTotalItem
                    column="VentasNoSujetas"
                    summary-type="sum"
                    :value-format="customCurrencyFormat"
                    display-format="${0}"
                    cssClass="right-column"
                    />
                    <DxTotalItem
                    column="ExportacionesDeServicios"
                    summary-type="sum"
                    :value-format="customCurrencyFormat"
                    display-format="${0}"
                    cssClass="right-column"
                    />
                    <DxTotalItem
                    column="TotalVentas"
                    summary-type="sum"
                    :value-format="customCurrencyFormat"
                    display-format="${0}"
                    cssClass="right-column"
                    />
                </DxSummary>
            </DxDataGrid>
        </template>
        
        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>
        <component v-for="win in windowsList" :is="win.component" :key="win.key" v-bind="win.props" />
    </div>
</template>

<script>

//import {mapState} from 'vuex';
import { DxDataGrid, DxPager, DxPaging, DxExport, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid';
import GeneralFilter from '../../../components/GeneralFilter.vue';
import Alerts from '@/components/Alerts.vue';
import Block from '@/components/Block.vue';
import { printToExcel } from "@/helpers/printToexcel";
import { jsPDF } from 'jspdf'
import 'jspdf-autotable';
import { exportDataGrid } from 'devextreme/pdf_exporter';

import SubPrint from '../SubPrint.vue';

export default {
    name: "SalesReport",
    components: {
        DxDataGrid,
        DxPager,
        DxPaging,
        SubPrint,
        Block,
        Alerts,
        GeneralFilter,
        //  PrintReportsSales,
        DxExport,
        DxSummary, 
        DxTotalItem
    },
    props: ['win'],
    watch: {
        reportType(newVal) {
            this.search.reportType = newVal;
        },
        dataType(newVal) {
            this.search.dataType = newVal;
        },
    },
    data() {
        return {
            windowsList: [

            ],
            print: {
                show: false,
                header: {
                    month: null,
                    year: null,
                    reportType: null,
                },
                data: [],
            },
            dataType: '01',
            printType: '1',
            reportType: '1',
            showPrint: false,
            reloadPrint: 0,
            DataTable: [],
            DataTable2: [],
            panel: 0,
            tab: null,
            key: 0,
            loading: false,
            search: {
                month: null,
                year: null,
                reportType: '1'
            },
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
            monthList: [
                { text: 'Enero', value: 1 },
                { text: 'Febrero', value: 2 },
                { text: 'Marzo', value: 3 },
                { text: 'Abril', value: 4 },
                { text: 'Mayo', value: 5 },
                { text: 'Junio', value: 6 },
                { text: 'Julio', value: 7 },
                { text: 'Agosto', value: 8 },
                { text: 'Septiembre', value: 9 },
                { text: 'Octubre', value: 10 },
                { text: 'Noviembre', value: 11 },
                { text: 'Diciembre', value: 12 },
            ],
            yearLIst: [
                { text: '2020', value: 2020 },
                { text: '2021', value: 2021 },
                { text: '2022', value: 2022 },
                { text: '2023', value: 2023 },
            ],
            columnsTaxPayer: [
                { dataField: "FECHA EMISIÓN DEL DOCUMENTO", caption: "FECHA EMISIÓN DEL DOCUMENTO" },
                { dataField: "CLASE DE DOCUMENTO", caption: "CLASE DE DOCUMENTO" },
                { dataField: "TIPO DE DOCUMENTO", caption: "TIPO DE DOCUMENTO" },
                { dataField: "NÚMERO DE RESOLUCIÓN", caption: "NÚMERO DE RESOLUCIÓN" },
                { dataField: "NUMERO DE SERIE DE DOCUMENTO", caption: "NUMERO DE SERIE DE DOCUMENTO" },
                { dataField: "NÚMERO DE DOCUMENTO", caption: "NÚMERO DE DOCUMENTO" },
                { dataField: "NÚMERO DE CONTROL INTERNO", caption: "NÚMERO DE CONTROL INTERNO" },
                { dataField: "NIT O NRC DEL CLIENTE", caption: "NIT O NRC DEL CLIENTE" },
                { dataField: "NOMBRE, RAZÓN SOCIAL O DENOMINACIÓN", caption: "NOMBRE, RAZÓN SOCIAL O DENOMINACIÓN" },
                { dataField: "VentasExentasFormatted", caption: "VENTAS EXENTAS" },
                { dataField: "VentasNoSujetasFormatted", caption: "VENTAS NO SUJETAS" },
                { dataField: "VentasGravadasLocalesFormatted", caption: "VENTAS GRAVADAS LOCALES" },
                { dataField: "DebitoFiscalFormatted", caption: "DÉBITO FISCAL" },
                { dataField: "VentasCuentaDeTercerosNoDomiciliadosFormatted", caption: "VENTAS A CUENTA DE TERCEROS NO DOMICILIADOS" },
                { dataField: "DebitoFiscalVentaTercerosFormatted", caption: "DÉBITO FISCAL POR VENTA A CUENTA DE TERCEROS" },
                { dataField: "TotalVentasFormatted", caption: "TOTAL VENTAS" },
            ],
            columnsTaxPayer2: [
                { dataField: "FECHA EMISIÓN DEL DOCUMENTO", caption: "Fecha" },
                { dataField: "NÚMERO DE DOCUMENTO", caption: "Código Generación" },
                { dataField: "", caption: "Form Único" }, //No existe
                { dataField: "NOMBRE, RAZÓN SOCIAL O DENOMINACIÓN", caption: "Cliente" },
                { dataField: "NIT O NRC DEL CLIENTE", caption: "NRC" },
                { dataField: "ExportacionesFormatted", caption: "Exportaciones"  }, //No existe
                { dataField: "VENTAS GRAVADAS LOCALES", caption: "Ventas Gravadas" }, //No existe
                { dataField: "DÉBITO FISCAL", caption: "Débito Fiscal" },
                { dataField: "DÉBITO FISCAL POR VENTA A CUENTA DE TERCEROS", caption: "Venta a Cuenta de Terceros" }, //No se si seria este campo
                { dataField: "ImpuestoPercibidoFormatted", caption: "Impuesto percibido" }, //No existe
                { dataField: "TOTAL VENTAS", caption: "Ventas Totales" },
            ],
            columnsFinalCustomer: [
                { dataField: "InvoiceDate", caption: "FECHA DE EMISIÓN" },
                { dataField: "ClaseDeDocumento", caption: "CLASE DE DOCUMENTO" },
                { dataField: "TipoDeDocumento", caption: "TIPO DE DOCUMENTO" },
                { dataField: "NumeroDeResolucion", caption: "NÚMERO DE RESOLUCIÓN" },
                { dataField: "SerieDeDocumento", caption: "SERIE DEL DOCUMENTO" },
                { dataField: "NumeroDeControlInternoDel", caption: "NÚMERO DE CONTROL INTERNO (DEL)" },
                { dataField: "NumeroDeControlInternoDelAl", caption: "NÚMERO DE CONTROL INTERNO (AL)" },
                { dataField: "NumeroDeDocumentoDel", caption: "NÚMERO DE DOCUMENTO (DEL)" },
                { dataField: "NumeroDeDocumentoAl", caption: "NÚMERO DE DOCUMENTO (AL)" },
                { dataField: "NoDeMaquinaRegistradora", caption: "NÚMERO DE MÁQUINA REGISTRADORA" },
                { dataField: "VentasExentas", caption: "VENTAS EXENTAS" },
                { dataField: "VentasInternasExentasNoSujetasAProporcionalidad", caption: "VENTAS INTERNAS EXENTAS NO SUJETAS A PROPORCIONALIDAD" },
                { dataField: "VentasNoSujetas", caption: "VENTAS NO SUJETAS" },
                { dataField: "VentasGravadasLocales", caption: "VENTAS GRAVADAS LOCALES" },
                { dataField: "ExportacionesDentroDelAreaCentroamericana", caption: "EXPORTACIONES DENTRO DEL ÁREA CENTROAMERICANA" },
                { dataField: "ExportacionesFueraDelAreaCentroamericana", caption: "EXPORTACIONES FUERA DEL ÁREA CENTROAMERICANA" },
                { dataField: "ExportacionesDeServicios", caption: "EXPORTACIONES DE SERVICIOS" },
                { dataField: "VentasAZonasFrancasYDPATasaCero", caption: "VENTAS A ZONAS FRANCAS Y DPA (TASA CERO)" },
                { dataField: "VentaACuentaDeTercerosNoDomiciliados", caption: "VENTAS A CUENTA DE TERCEROS NO DOMICILIADOS" },
                { dataField: "TotalVentas", caption: "TOTAL VENTAS" },
                { dataField: "NumeroDeAnexo", caption: "NÚMERO DE ANEXO" },
            ],
            columnsFinalCustomer2: [ //UTILIZA EL sp_Ventas_Consumidor_Final_Agrupado
                { dataField: "InvoiceDate", caption: "Fecha" },
                { dataField: "NumeroDeDocumentoDel", caption: "Código de Generación Inicial" },
                { dataField: "NumeroDeDocumentoAl", caption: "Código de Generación Final" },
                { dataField: "NumeroControlInicial", caption: "Número de Control Del" },
                { dataField: "NumeroControlFinal", caption: "Número de Control Al" },
                { dataField: "VentasExentas", caption: "Ventas Exentas" },
                { dataField: "VentasNoSujetas", caption: "Ventas No Sujetas" },
                { dataField: "VentasGravadasLocales", caption: "Ventas Gravadas" },
                { dataField: "ExportacionesDeServicios", caption: "Exportaciones" }, //No se si seria este
                { dataField: "TotalVentas", caption: "Venta Total" },
            ],
            nameDxDataGrid: "dataGrid",
            hidePagination: false
        }
    },
    computed: {

    },
    async mounted() {
        this.yearLIst = (await this.$API.accountingreports.getSalesDateYears()).years;
        this.search.year = this.yearLIst[this.yearLIst.length - 1].AÑO;
        this.monthList = (await this.$API.accountingreports.getSalesDateMonths(this.search)).months
        this.search.month = this.monthList[this.monthList.length - 1].monthIndex;
    },
    methods: {
        customCurrencyFormat(value) {
            if (value == null) return ''; 
            return parseFloat(value).toFixed(2); 
        },
        onExportingToPdf() {
            const doc = this.reportType == '1' ? new jsPDF('landscape') : new jsPDF(); 
            var spColumnsWidth = []

            if (this.reportType == '1') {
                spColumnsWidth = [16, 52, 18, 34, 25, 20, 20, 20, 20, 20, 20]
            }

            if (this.reportType == '2') {
                spColumnsWidth = [16, 23, 23, 23, 23, 14, 14, 14, 14, 14]
                //spColumnsWidth = [16, 35, 35, 35, 35, 22, 22, 22, 22, 22]
            }

            exportDataGrid({
                jsPDFDocument: doc,
                component: this.$refs[this.nameDxDataGrid + this.reportType + this.printType].instance,
                margin: { top: 40, right: 10, bottom: 20, left: 10 },
                columnWidths: spColumnsWidth,
                customizeCell: function (options) {
                    options.pdfCell.font.size = 7;
                },
            }).then(() => {
                var pageCount = parseInt(doc.internal.getNumberOfPages()) + 1;
                var header = ""
                var header2 = ""
                var header3 = ""
                var header4 = ""
                var header5 = ""

                for(var i = 0; i < pageCount; i++){
                    doc.setPage(i + 1);
                    doc.setFontSize(7)
                    
                    if (!this.hidePagination) {
                        let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
                        doc.text ('page:  ' + pageCurrent + ' / ' + pageCount ,10,10);
                    }

                    if (this.reportType == "1") {
                        let month = this.monthList.find(item => item.monthIndex === this.search.month)
                        header = `${JSON.parse(localStorage.getItem("user")).business}`
                        header2 = `Libro de Ventas a Contribuyentes`
                        header3 = `Registro de IVA Número: ${JSON.parse(localStorage.getItem("user")).businessnrc}`
                        header4 = `Periodo Tributario: ${month.MES} de ${this.search.year}`
                        header5 = `Cantidades Expresadas en Dolares de Estados Unidos de America`
                    }

                    if (this.reportType == "2") {
                        let month = this.monthList.find(item => item.monthIndex === this.search.month)
                        header = `${JSON.parse(localStorage.getItem("user")).business}`
                        header2 = `Libro de Ventas a Consumidor`
                        header3 = `Registro de IVA Número: ${JSON.parse(localStorage.getItem("user")).businessnrc}`
                        header4 = `Periodo Tributario: ${month.MES} de ${this.search.year}`
                        header5 = `Cantidades Expresadas en Dolares de Estados Unidos de America`
                    }
                    
                    const pageWidth = doc.internal.pageSize.getWidth();
                    const headerWidth = doc.getTextDimensions(header).w;
                    const headerWidth2 = doc.getTextDimensions(header2).w;
                    const headerWidth3 = doc.getTextDimensions(header3).w;
                    const headerWidth4 = doc.getTextDimensions(header4).w;
                    const headerWidth5 = doc.getTextDimensions(header5).w;
                    doc.setFontSize(10);
                    doc.text(header, (pageWidth - headerWidth) / 2, 8);
                    doc.text(header2, (pageWidth - headerWidth2) / 2, 13);
                    doc.text(header3, (pageWidth - headerWidth3) / 2, 18);
                    doc.text(header4, (pageWidth - headerWidth4) / 2, 23);
                    doc.text(header5, (pageWidth - headerWidth5) / 2, 28);
                }

                doc.addPage();
                doc.setFontSize(7)

                if(!this.hidePagination) {
                    doc.text ('page:  ' + pageCount + ' / ' + pageCount ,10,10);
                }
                
                const pageWidth = doc.internal.pageSize.getWidth();
                const headerWidth = doc.getTextDimensions(header).w;
                const headerWidth2 = doc.getTextDimensions(header2).w;
                const headerWidth3 = doc.getTextDimensions(header3).w;
                const headerWidth4 = doc.getTextDimensions(header4).w;
                const headerWidth5 = doc.getTextDimensions(header5).w;
                doc.setFontSize(10);
                doc.text(header, (pageWidth - headerWidth) / 2, 8);
                doc.text(header2, (pageWidth - headerWidth2) / 2, 13);
                doc.text(header3, (pageWidth - headerWidth3) / 2, 18);
                doc.text(header4, (pageWidth - headerWidth4) / 2, 23);
                doc.text(header5, (pageWidth - headerWidth5) / 2, 28);

                doc.setFontSize(10);
                if (this.reportType == "1") {
                    //CONTRIBUYENTES
                    const gravadas = this.DataTable.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue["VENTAS GRAVADAS LOCALES"]
                    }, 0)
                    const debitoFiscal = this.DataTable.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue["DÉBITO FISCAL"]
                    }, 0)

                    //CONSUMIDORES FINALES
                    const gravadas2 = this.DataTable2.reduce((accumulator, currentValue) => {
                        return accumulator + parseFloat(currentValue["VentasGravadasLocales"])
                    }, 0)
                    const nosujetas2 = this.DataTable2.reduce((accumulator, currentValue) => {
                        return accumulator + parseFloat(currentValue["VentasNoSujetas"])
                    }, 0)
                    const debitoFiscal2 = this.DataTable2.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue["Iva"]
                    }, 0)
                    const exportaciones2 = this.DataTable2.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue["ExportacionesDeServicios"]
                    }, 0)
                    const exentas2 = this.DataTable2.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue["VentasExentas"]
                    }, 0)

                    const total = parseFloat(gravadas) + parseFloat(debitoFiscal)
                    const total2 = parseFloat(gravadas2) + parseFloat(nosujetas2) + parseFloat(exportaciones2) + parseFloat(exentas2)
                    const totalGravadas = parseFloat(gravadas) + parseFloat(gravadas2)
                    const totalExentas = parseFloat(exentas2)
                    const totalExportC = parseFloat(exportaciones2)
                    const totalDebitoFiscal = parseFloat(debitoFiscal) + parseFloat(debitoFiscal2)
                    const totalTotal = parseFloat(total) + parseFloat(total2)


                    const footerData = [
                        ['DETALLE DEL LIBRO', 'EXENTAS', 'GRAVADAS', 'EXPORTC.', 'DEBITO FISCAL', 'TOTAL', 'IVA RETENIDO'],
                        ['CONSUMIDORES FINALES', parseFloat(exentas2).toFixed(2), parseFloat(gravadas2).toFixed(2), parseFloat(exportaciones2), parseFloat(debitoFiscal2).toFixed(2), parseFloat(total2).toFixed(2), '0.00'],
                        ['CONTRIBUYENTES', '0.00', parseFloat(gravadas).toFixed(2), '0.00', parseFloat(debitoFiscal).toFixed(2), parseFloat(total).toFixed(2), '0.00'],
                        ['TOTALES', parseFloat(totalExentas).toFixed(2), parseFloat(totalGravadas).toFixed(2), parseFloat(totalExportC), parseFloat(totalDebitoFiscal).toFixed(2), parseFloat(totalTotal).toFixed(2), '0.00'],
                    ];

                    let contentEndY = 33;

                    const tableConfig = {
                        startY: contentEndY, // Comienza en la posición Y indicada (260)
                        head: [footerData[0]], // La primera fila es el encabezado
                        body: footerData.slice(1), // Las filas siguientes son los datos
                        theme: 'plain', // Estilo de la tabla
                        headStyles: { fillColor: [255, 255, 255], fontSize: 10 },
                        bodyStyles: { fontSize: 10 },
                        margin: { left: 5, right: 5 }, // Márgenes
                        columnStyles: {
                            0: { cellWidth: 'auto' }, // La primera columna (DETALLE DEL LIBRO) tiene ancho automático
                            1: { halign: 'right' }, // Alinear a la derecha la columna 'EXENTAS'
                            2: { halign: 'right' }, // Alinear a la derecha la columna 'GRAVADAS'
                            3: { halign: 'right' }, // Alinear a la derecha la columna 'EXPORTC.'
                            4: { halign: 'right' }, // Alinear a la derecha la columna 'DEBITO FISCAL'
                            5: { halign: 'right' }, // Alinear a la derecha la columna 'TOTAL'
                            6: { halign: 'right' }, // Alinear a la derecha la columna 'IVA RETENIDO'
                        }, 
                        tableWidth: 'auto', // Ancho automático de las columnas
                    };

                    doc.autoTable(tableConfig);

                    const footerText = `
                                                                    _______________________________                                _____________________________
                                                                                NOMBRE CONTADOR                                                                FIRMA
                                `
                    contentEndY = 85
                    doc.text(footerText, 10, contentEndY);
                }

                if (this.reportType == "2") {

                    const gravadas = this.DataTable2.reduce((accumulator, currentValue) => {
                        return accumulator + parseFloat(currentValue["VentasGravadasLocales"])
                    }, 0)
                    const nosujetas = this.DataTable2.reduce((accumulator, currentValue) => {
                        return accumulator + parseFloat(currentValue["VentasNoSujetas"])
                    }, 0)
                    const debitoFiscal = this.DataTable2.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue["Iva"]
                    }, 0)
                    const exportaciones = this.DataTable2.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue["ExportacionesDeServicios"]
                    }, 0)
                    const exentas = this.DataTable2.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue["VentasExentas"]
                    }, 0)

                    const total = gravadas + nosujetas + exportaciones + exentas

                    const footerText = `
                                    CALCULO DEL DÉBITO FISCAL: \n
                                    VENTAS NETAS GRAVADAS                                                     ${parseFloat(gravadas).toFixed(2)} \n
                                    13% DEBITO FISCAL                                                                  ${parseFloat(debitoFiscal).toFixed(2)} \n
                                    EXPORTACIONES                                                                      ${parseFloat(exportaciones).toFixed(2)} \n
                                    EXENTAS                                                                                     ${parseFloat(exentas).toFixed(2)} \n
                                    NO SUJETAS                                                                               ${parseFloat(nosujetas).toFixed(2)} \n
                                    ---------------------------
                                    TOTAL VENTA GRAVADA                                                           ${parseFloat(total).toFixed(2)}\n\n\n

                                    _______________________________                _____________________________
                                                NOMBRE CONTADOR                                                FIRMA
                                `;
                    let contentEndY = 45;
                    doc.text(footerText, 10, contentEndY);
                }

                if (this.reportType == "1") {
                    let month = this.monthList.find(item => item.monthIndex === this.search.month)
                    doc.save(`Reporte de ventas (Contribuyentes) - ${month.MES} de ${this.search.year}.pdf`);
                }

                if (this.reportType == "2") {
                    let month = this.monthList.find(item => item.monthIndex === this.search.month)
                    doc.save(`Reporte de ventas (Consumidores Finales) - ${month.MES} de ${this.search.year}.pdf`);
                }
                
            });
        },
        async getmonths() {
            this.monthList = (await this.$API.accountingreports.getSalesDateMonths(this.search)).months
            this.search.month = this.monthList[this.monthList.length - 1].monthIndex;
        },
        filterHandler(data) {
            if (this.reportType === '1') {
                this.DataTable = data.data;
                //this.DataTable2 = data.data2;
            } else {
                this.DataTable2 = data.data;
            }
        },
        getReportSales() {
            if (this.search.month == null || this.search.year == null) {
                return;
            }

            this.loading = true;
            this.$API.accountingreports.getSalesByDate({ ...this.search, reportType: this.reportType, dataType: this.dataType }).then((res) => {
                if (this.reportType === '1') {
                    this.DataTable = res.data.data1;
                    this.DataTable2 = res.data.data2;
                } else {
                    console.log(res)
                    this.DataTable2 = res.data;
                }
                this.loading = false;
            }).catch((err) => {
                this.loading = false;
                this.showAlert("danger", "Error", err);
            });
        },
        printconfig() {
            this.showPrint = true;
            this.print.show = false;
            const month = this.monthList.find((element) => element.monthIndex == this.search.month);

            this.print.header.month = month.MES;
            this.print.header.year = this.search.year;
            this.print.header.reportType = this.reportType;
            this.print.data.body = this.reportType === '1' ? this.DataTable : this.DataTable2;
            console.log(this.print.data.body);
            //this.reloadPrint++;
            this.print.show = true;

            this.windowsList.push({
                component: SubPrint,
                props: {
                    print: this.print,
                },
                key: new Date().getTime(),
            });

        },
        closeAlert() {
            this.alert.show = false;
        },
        acceptAlert() {
            this.alert.show = false;


        },
        onExporting(e) {
            let array = null;
            if (this.reportType === '1') {
                array = ['Exportaciones', 'Ventas Gravadas', 'Débito Fiscal', 'Venta a Cuenta de Terceros', 'Débito Fiscal a Cuenta de Terceros',
                    'No Sujeta', 'Exenta', 'Retencion', 'Impuesto Percibido', 'Ventas Totales'
                ];
            } else {
                array = ['Ventas Exentas', 'Ventas Gravadas', 'Exportaciones', 'Venta Total'];
            }
            console.log(array);
            printToExcel(e, "Reporte de ventas", array);
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
    },

}
</script>

<style scoped>
.bar {
    width: 100%;
    background-color: rgb(60, 60, 60) !important;
    color: white !important;
    border-style: solid;
    border-color: black;
    border-radius: 15px !important;
    margin-top: 1rem !important;
}

.btn-add {
    background-color: #3498DB !important;
    color: white;
}

.btnsave {
    background-color: #00A178 !important;
    color: white;
}

.btnclean {
    background-color: #F29D35 !important;
    color: white;
}

.btndelete {
    background-color: #E3191E !important;
    color: white;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row .right-column {
    text-align: center !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row .left-column {
    text-align: center !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row .center-column {
    text-align: center !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-data-row .right-column {
    text-align: right !important;
    font-size: 10px;
}

.dx-datagrid-content .dx-datagrid-table .dx-data-row .left-column {
    text-align: left !important;
    font-size: 10px;
}

.dx-datagrid-content .dx-datagrid-table .dx-data-row .center-column {
    text-align: center !important;
    font-size: 10px;
}
</style>
